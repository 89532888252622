<template>
  <nav class="text-white text-base font-semibold pt-3">
    <router-link :to="`/projects/${this.projectId}/recipients`" class="flex items-center active-nav-link text-white py-2 pl-6 nav-item">
      Recipients
    </router-link>
    <router-link :to="`/projects/${this.projectId}/messages`" class="flex items-center active-nav-link text-white py-2 pl-6 nav-item">
      Messages
    </router-link>
    <router-link :to="`/projects/${this.projectId}/scheduling`" class="flex items-center active-nav-link text-white py-2 pl-6 nav-item">
      Scheduling
    </router-link>
    <router-link :to="`/projects/${this.projectId}/settings`" class="flex items-center active-nav-link text-white py-2 pl-6 nav-item">
      Settings
    </router-link>
    <router-link to="/" class="flex items-center active-nav-link text-gray-400 pt-20 py-4 pl-6 nav-item font-light">
      Back to overview
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'ProjectLayout',

  data() {
    return {
      projectId: null
    }
  },
  created() {
    this.projectId = this.$route.params.projectId
  },
}
</script>